@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    left: 0;
    position: absolute;
    animation: spin 2s linear infinite;
}

.spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 26px;
    height: 26px;
}
