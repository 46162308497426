.card {
  margin-bottom: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.card :global(.ant-card-body) {
  width: 100%;
  padding: 0;
}


.welcome {
  font-size: 48px;
  text-align: center;
}

.list {
  min-width: 160px;
  height: 100%;
  overflow: scroll;
  border: none;
}

.contentCenter {
  display: flex;
  justify-content: center;
}

.assistant {
  width: 24px;
  height: 24px;
  background: transparent;
}

.user {
  background: #1677ff;
}

.marginLeft {
  margin-left: 8px;
}

.marginBtm {
  margin-bottom: 8px;
}

.pRight {
  text-align: right;
  margin-bottom: 0;
}

.pLeft {
  text-align: left;
  margin-bottom: 0;
}

.pRight :global(p),
.pLeft :global(p) {
  font-size: 16px;
  margin-bottom: 0;
}

.chat {
  padding: 24px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: calc(100vh - 24px);
}

.chatHistory {
  box-shadow: inset 4px 0 10px -5px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(0deg, #fbfbfb, #fbfbfb),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 96.78%, rgba(0, 0, 0, 0.05) 100%);
  border-radius: 0 24px 24px 0;
}

.aiSuggestion {
  font-size: 18px;
  margin: 0;
}

.cardText {
  text-align: center;
}

.logoChat {
  margin-bottom: 24px;
}

.spaceBtw {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.cardGpt {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 284px;
  border-radius: 24px;
  border: 1px solid #ff604b;
  padding: 12px;
  height: 120px;
  cursor: pointer;
}

.cardGpt:hover {
  border: 1px solid #ffcc4b;
  background-color: #fafafa;
  cursor: pointer;
}

.searchBar {
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 5px;
  width: 840px;
  z-index: 1;
  flex-flow: row;
}

.searchBarInput {
  width: 720px;
}

.chatBtn {
  text-align: left;
  padding: 20px 24px;
  border: none;
  background: transparent;
  width: 100%;
}

.chatBtn:hover {
  background: #ffffff;
  box-shadow: 2px 3px 4px 0 #00000040;
}

.gridUser {
  margin: 24px 0;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto 20px;
  justify-content: end;
}

.gridAssistant {
  margin: 24px 0;
}

.gridAssistant:last-child {
  margin-bottom: 48px;
  /*padding-bottom: 24px;*/
}

.borderMarkdown {
  border: 0.5px solid #d3d3d3;
  padding: 20px;
  gap: 10px;
  border-radius: 20px;
}

@media (max-width: 576px) { /* Adjust the breakpoint as needed */
  .chatHistory {
    display: none !important;
  }
  .searchBar {
    display: flex;
    align-items: center;
    bottom: -10px;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    z-index: 1;
    flex-flow: row;
  }

  .searchBarInput {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gridAssistant > div:first-child > div {
    width: var(--logo-size-extra-small);
  }
  .content {
    width: 100%;
    margin: 8px 0;
    overflow: scroll;
  }
  .chat {
    padding: 12px 12px 24px 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    height: calc(100vh - 24px);
  }
  .list {
    display: none;
  }
}
