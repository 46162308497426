.bg {
    height: 100vh;
    background-image: url('/public/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 400px;
}

.formWidth {
    width: 320px;
}
