.reactFlow {
    display: flex;
    height: calc(100vh - 300px);
}

.editorBorder {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.editorBorderError {
    border: 1px solid #d62828;
}
