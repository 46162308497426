.logo-container {
  aspect-ratio: 1/1;
}

.logo-extra-small {
  width: var(--logo-size-extra-small);
}

.logo-small {
  width: var(--logo-size-small);
}

.logo-default {
  width: var(--logo-size-default);
}

.logo-large {
  width: var(--logo-size-large);
}

.logo-extra-large {
  width: var(--logo-size-extra-large);
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
