.page {
  padding: 24px;
  height: 100vh;
  background-image: url("/public/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 300px;
}

.newLineP {
  white-space: pre;
}
