:root {
  /* Colors */
  --primary-color: #ff604b;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;

  /* Logo Sizes */
  --logo-size-extra-small: 32px;
  --logo-size-small: 45px;
  --logo-size-default: 75px;
  --logo-size-large: 135px;
  --logo-size-extra-large: 200px;
}
