.bg {
    height: 100vh;
    background-image: url('/public/bg.svg');
    background-repeat:round;
    background-size: cover;
}

.formWidth {
    width: 360px;
}
