.menu {
  margin-top: 24px;
}

.content {
  padding: 12px 12px 12px 4px;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #001529;
}

.bg {
  position: absolute;
  background-size: contain;
  filter: blur(5px);
  width: 50%;
  height: 50%;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .content {
    padding: 12px;
  }
}

.logo {
  margin-top: 14px;
  margin-bottom: 28px;
}

.btn {
  margin-bottom: 24px;
}

.user {
  color: white;
  font-size: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.userRow {
  margin-top: 24px;
  margin-left: 24px;
}
