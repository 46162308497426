@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jersey+15&display=swap");
@import "../src/styles/variables.css";

body {
  font-family: Ubuntu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-y; /* Restrict touch actions to vertical scrolling only */
  overscroll-behavior: none;
  height: 100%;
}

.jersey-15-regular {
  font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.full-width {
  width: 100%;
}

.center-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

body .no-margin {
  margin-bottom: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.card {
  max-width: 640px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.no-pad {
  padding: 0;
}

.ant-switch-inner {
  background-color: var(--primary-color);
}

.Page_content {
  padding: 12px 12px 12px 4px;
}
